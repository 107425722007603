<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <div class=" pr-5">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Employee
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class=" flex flex-col p-4 ">
        <Card class="p-5 mt-5">
    <div class="w-full px-5 mt-5 mb-4 pb-10" style="margin: 0 auto;">
      <div class=" w-full mt-6">
        <span
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
        </span>
      </div>
      <div class="mt-10">
          <Table
          :headers="groupHeaders"
          :items="content"
          class="w-full mt-4 mb-20"
          :has-number="true"
          :loading="false"
          />
      </div>
    </div>
    </Card>
  </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from '@/components/Card';

export default {
  components: {
      Breadcrumb,
      Table,
      Card
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Employee",
          href: "Employee",
          id: "Employee"
        },
        {
          disabled: false,
          text: "Groups",
          id: "Groups"
        }
      ],
      openModal: false,
      content: [],
      groups: [],
      loading: true,
      groupHeaders: [
        { title: "group name", value: "groupName" },
        { title: "group type", value: "groupType" },
        { title: "members", value: "members" },
        { title: "group admin", value: "groupAdmin" },
        { title: "Date Created", value: "createdAt" }
      ]
    };
  },
  methods: {
    async getUserGroups() {
      const result = await this.$_getUserGroups(this.$AuthUser.id);
      this.groups = result.data.groups;
      result.data.groups.map(item => {
        if (item.belongsToGroup) {
          this.content.push({
            groupName: item.name,
            groupType: item.type,
            members: item.membersCount,
            groupAdmin: item.superAdmin
              ? `${item.superAdmin.fname} ${item.superAdmin.lname}`
              : "-",
            createdAt: this.$DATEFORMAT(
              new Date(item.createdAt),
              "MMMM dd, yyyy"
            ),
            id: item.id
          });
        }
        return this.content;
      });
      this.loading = false;
    },
    handleClose() {
      this.content = [];
      this.loading = true;
      this.getUserGroups();
      this.openModal = false;
    }
  },
  mounted() {
    this.getUserGroups();
  }
};
</script>
<style scoped>
.tableShadow {
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
}
</style>